import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCheck,
  faUserCheck,
  faClipboardList,
  faComments,
} from "@fortawesome/free-solid-svg-icons";

import PageLayout from "../components/PageLayout";
import MoneyHousesImg from "../components/Images/money-houses-img";

const AvaluosPage = () => (
  <PageLayout>
    <Container className="page-layout">
      <Row>
        <Col className="mt-5">
          <h4 className="text-center text-uppercase">Aval&uacute;os</h4>
          <hr className="title-line" />
        </Col>
      </Row>
      <Row>
        <Col md="6" lg={{ size: 5, offset: 1 }}>
          <MoneyHousesImg />
        </Col>
        <Col className="mt-3">
          <h5 className="mt-3">
            ¿Necesita saber el valor real de su propiedad?
          </h5>
          <ul className="welcome-list list-unstyled">
            <li className="d-flex mb-3">
              <span className="mr-2">
                <FontAwesomeIcon icon={faCheck} color="#BCA068" fixedWidth />
              </span>
              <span>Bajo las normas NIIF o normas nacionales.</span>
            </li>
            <li className="d-flex mb-3">
              <span className="mr-2">
                <FontAwesomeIcon icon={faCheck} color="#BCA068" fixedWidth />
              </span>
              <span>Entrega del informe en 3 dias h&aacute;biles.</span>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 10, offset: 1 }}>
          <h4 className="text-center text-uppercase mt-5">
            Aval&uacute;os en tres pasos
          </h4>
          <hr className="title-line" />
          <ul className="welcome-list list-unstyled mt-4">
            <li className="mb-3">
              <FontAwesomeIcon
                icon={faComments}
                className="w-100 text-center mb-3"
                size="3x"
                color="#BCA068"
                fixedWidth
              />
              <h5>1.&nbsp; Solicitar Aval&uacute;o.</h5>
              <p>
                Cont&aacute;ctenos a trav&eacute;s de nuestros canales y
                solicite el formulario de aval&uacute;o.
              </p>
            </li>
            <li className="mb-3">
              <FontAwesomeIcon
                icon={faUserCheck}
                className="w-100 text-center mb-3"
                size="3x"
                color="#BCA068"
                fixedWidth
              />
              <h5>2.&nbsp; Visita del perito.</h5>
              <p>Nuestro perito visitar&aacute; el inmueble a avaluar.</p>
            </li>
            <li className="mb-3">
              <FontAwesomeIcon
                icon={faClipboardList}
                className="w-100 text-center mb-3"
                size="3x"
                color="#BCA068"
                fixedWidth
              />
              <h5>3.&nbsp; Entrega del informe</h5>
              <p>
                El informe se entregar&aacute; 3 Días h&aacute;biles
                despu&eacute;s de la inspecci&oacute;n.
              </p>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs="12" lg={{ size: 10, offset: 1 }}>
          <h4 className="text-center text-uppercase mt-5">Requisitos</h4>
          <hr className="title-line" />
          <ul className="welcome-list">
            <li>Certificado de libertad.</li>
            <li>Copia de la escritura del inmueble.</li>
            <li>Copia del impuesto predial del ultimo a&ntilde;o.</li>
          </ul>
        </Col>
        <Col lg={{ size: 10, offset: 1 }}>
          <h4 className="text-center text-uppercase mt-5">¿Necesita ayuda?</h4>
          <hr className="title-line" />
          <p>Estamos aqu&iacute; para ayudarlo con lo que necesite:</p>
          <div className="d-flex flex-column text-center">
          <div className="p-4 text-center">
            <a href={`mailto:${process.env.GATSBY_COMERCIAL_EMAIL}`}>
              {process.env.GATSBY_COMERCIAL_EMAIL}
            </a>
            <a
              href={`tel:${process.env.GATSBY_AVALUOS_CELLPHONE}`}
              className="d-block mt-3"
            >
              {process.env.GATSBY_AVALUOS_CELLPHONE_DISPLAY}
            </a>
          </div>
          </div>
        </Col>
      </Row>
    </Container>
  </PageLayout>
);

export default AvaluosPage;
