import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const MoneyHousesImg = () => (
  <StaticImage
    src="../../images/money-houses.png"
    alt="Orozco Laverde"
  />
);

export default MoneyHousesImg;
